/* eslint-disable */
import React, { useState } from 'react';
import { graphql } from 'gatsby';
import Layout from '../containers/layout';
import HeaderPage from '../components/HeaderPage';
import Faq from '../components/Faq';
import VisualComposer from "../components/VisualComposer"

const FaqTemplate = ({data, pageContext}) => {
  const {
    title,
    label,
    faqModularBlock,
    seoMetaTags
  } = data.datoCmsFaq;

  return (
    <Layout
      seoMetaTags={seoMetaTags}
      pathname={pageContext.pathname}
      communicationBar={pageContext.communicationBar}
      pageType="FAQ Page"
      canonical={pageContext.canonical}
    >
      <HeaderPage
        title={title}
        model={label}
        isRoot
      />
      {
        faqModularBlock &&  (
          <Faq accordionsBlock={faqModularBlock} />
        )
      }
      {
        data && data.datoCmsFaq && (
          <VisualComposer
            components={data.datoCmsFaq.visualComposer}
          />
        )
      }
    </Layout>
  )
}

export default FaqTemplate;

export const FAQ_QUERY = graphql`
  query faq_query {
    datoCmsFaq {
      id
      seoMetaTags {
        tags
      }
      model {
        apiKey
      }
      title
      slug
      label
      seoMeta {
        title
        description
      }
      faqModularBlock {
        id
        titleCategoryFaq
        slug
        accordions {
          id
          title
          content
        }
      }
      visualComposer {
        ...BannerBigImage
        ...BannerDouble
        ...BannerText
        ...CenteredNumbers
        ...Numbers
        ...Spacing
      }
    }
  }
`;
