import React, { useState, useEffect } from 'react';
import Container from '../Container';
import Select from '../Select';
import Icon from '../Icons';
import Accordion from '../Accordion';
import AccordionRichText from '../AccordionRichText';

const Faq = ({ accordionsBlock }) => {
  const [activeAccordion, setActiveAccordion] = useState('');

  const handleChangeFaq = (value) => {
    if (typeof window !== 'undefined') {
      window.location.hash = `#${value}`;
    }
  };

  const hash = typeof window !== 'undefined' ? window.location.hash : '';

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (window.location.hash) {
        setActiveAccordion(window.location.hash.split('#')[1]);
      } else {
        setActiveAccordion(accordionsBlock[0].slug);
      }
    } else {
      setActiveAccordion(accordionsBlock[0].slug);
    }
  }, [hash]);

  const options = [...accordionsBlock.map((accordionGroup) => {
    return {
      label: accordionGroup.titleCategoryFaq,
      value: accordionGroup.slug
    };
  })];

  return (
    <Container className="faq">
      <div className="faq__select-container">
        <div className="faq__select-content">
          <span className="label-small faq__select-placeholder">Argomenti</span>
          <Icon className="faq__select-icon icon--24" path="arrow-small-down" />
        </div>
        <Select
          options={options}
          label="argomenti"
          className="faq__select"
          handleChange={handleChangeFaq}
        />
      </div>
      {
        accordionsBlock
        && (
          <div className="faq__content-wrapper">
            <Container className="navigation-third-level">
              <h3 className="navigation-third-level__title heading-xs">Argomenti</h3>
              <ul className="navigation-third-level__list">
                {
                  accordionsBlock && accordionsBlock.map((accordionList) => {
                    const { id, titleCategoryFaq, slug } = accordionList;
                    const isActive = activeAccordion === slug;

                    return (
                      <li key={id} className="navigation-third-level__list-item">
                        <a href={`#${slug}`} className={`list-item__link caption ${isActive ? 'active' : ''}`}>
                          {titleCategoryFaq}
                        </a>
                      </li>
                    );
                  })
                }
              </ul>
            </Container>
            <Container className="faq__accordion-list">
              {
                accordionsBlock && accordionsBlock.map((accordionList) => {
                  const { id, accordions, slug } = accordionList;
                  return (
                    <div key={id} className={`faq__accordions ${activeAccordion === slug ? 'active' : ''}`}>
                      {
                        accordions && accordions.map((accordion) => {
                          const { title, content } = accordion;
                          return (
                            <Accordion key={accordion.id} title={title} className="faq__accordion">
                              <AccordionRichText content={content} />
                            </Accordion>
                          );
                        })
                      }
                    </div>
                  );
                })
              }
            </Container>
          </div>
        )
      }
    </Container>
  );
};

export default Faq;
